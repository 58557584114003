import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { get } from 'lodash';

import { logger } from 'client/utils/isomorphic-logger';

import { getPlural } from 'client/utils/plural';
import { getPriceString } from 'site-modules/shared/utils/price-utils';
import { getDisplayHeadline } from 'site-modules/shared/components/native-ad/utils/offer-incentives-ad-utils';

import { usurpSsaStl } from 'site-modules/shared/components/native-ad/usurp-site-served-ad/usurp-site-served-ad-styles';
import { svgCarTrims, svgColorPalette, svgStreeringWheel } from 'client/images/inline-svgs/ads/buildprice-ad-icons';

import { VehicleEntities } from 'client/data/models/vehicle';
import { isUsedState } from 'site-modules/shared/utils/publication-states';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ConquestRibbon } from 'site-modules/shared/components/native-ad/native-ad-components/conquest-ribbon/conquest-ribbon';
import { CtaButtonText } from 'site-modules/shared/components/native-ad/native-ad-components/cta-button-text/cta-button-text';
import { SRPCreativeConfigPropTypes } from 'client/data/models/native-ads-creative-config';
import classnames from 'classnames';

const CTA = 'VIEW ALL OFFERS';
const HEADLINE_DEFAULT = 'Special offers available';

const ADS_10212_SUBHEADS = [
  { title: 'Choose Trim', icon: 'icon-car2' },
  { title: 'Add Options', icon: 'icon-plus-circle2' },
  { title: 'Calculate Price', icon: 'icon-price-tags2' },
];

function getMsrp(msrp, vehicleInfo) {
  const msrpPrice = typeof msrp === 'string' ? msrp.trim() : get(vehicleInfo, 'submodels[0].MSRP');
  if (msrpPrice === '') {
    logger(
      'warn',
      'SRP Usurp site-served ad: msrp is empty string value. Should be a string, either in formatted price or whitespace.'
    );
  }
  return getPriceString(msrpPrice, '');
}

function AtGlanceItem({ icon, text }) {
  return (
    <li className="pr-0_5 text-nowrap mb-1 d-flex align-items-center">
      {icon}
      <ContentFragment componentToUse="span">{text}</ContentFragment>
    </li>
  );
}

AtGlanceItem.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
};

export function UsurpSiteServedContent({ creativeConfig, targetVehicle, mobile, ads10212Recipe }) {
  const {
    zipCode,
    ipZipCode,
    offersNumber,
    creativeConfigData: {
      headline,
      logo,
      photo,
      photoDisclaimer,
      linkDisplayUrl,
      cta,
      isConquest,
      msrp,
      vehicleInfo,
      trimNames,
    },
  } = creativeConfig;

  const headlineText = getDisplayHeadline(headline, offersNumber, zipCode, ipZipCode, HEADLINE_DEFAULT, 14, 10);
  const msrpPrice = getMsrp(msrp, vehicleInfo);
  const colorCnt = get(vehicleInfo, 'submodels[0].extColorCnt');
  const trimCnt = get(trimNames, 'length');
  const packageCnt = get(vehicleInfo, 'submodels[0].pkgCnt');
  const modelNmae = get(vehicleInfo, 'modelName');

  const isUsed = isUsedState(targetVehicle?.publicationState);
  const showMsrpPrice = !(isUsed && mobile);

  const msrpPriceDisplay = !!msrpPrice && (
    <Col xs={5} style={usurpSsaStl.borderLeft} className="pr-0 text-right">
      <div className="heading-3">{msrpPrice}</div>
      <div className="small">starting MSRP</div>
    </Col>
  );

  const isAds10212Enabled = ['chal1', 'chal2', 'chal3'].includes(ads10212Recipe);
  const isAds10212Chal1 = ads10212Recipe === 'chal1';
  const isADS10212Chal2 = ads10212Recipe === 'chal2';

  const renderAtAGlanceList = (
    <Fragment>
      {!!colorCnt && <AtGlanceItem icon={svgColorPalette} text={`${colorCnt} ${getPlural('Color', colorCnt)}`} />}

      {!!trimCnt && <AtGlanceItem icon={svgCarTrims} text={`${trimCnt} ${getPlural('Trim', trimCnt)}`} />}

      {!!packageCnt && (
        <AtGlanceItem icon={svgStreeringWheel} text={`${packageCnt} ${getPlural('Package', packageCnt)}`} />
      )}
    </Fragment>
  );

  return (
    <Fragment>
      <div className="pos-r" style={{ height: '200px' }}>
        <figure className="pos-r m-0 mx-auto" style={usurpSsaStl.jellyBean}>
          <img src={photo} alt="" loading="lazy" style={usurpSsaStl.img} />
        </figure>
        <div
          className="disclaimer persistent-disclaimer text-gray-dark text-right pr-1"
          style={usurpSsaStl.persistentDisclaimer}
        >
          {photoDisclaimer}
        </div>
      </div>
      {isConquest ? (
        <ConquestRibbon style={usurpSsaStl.conquestTopLeft} />
      ) : (
        <div className="oem-logo-top-left" style={usurpSsaStl.oemLogoTopLeft}>
          <img className="oem-logo" src={logo} alt="" loading="lazy" style={usurpSsaStl.oemLogo} />
        </div>
      )}
      <div className="p-1 d-flex flex-column" style={{ flex: 'auto' }}>
        <Row className="mx-0 mb-1 pb-0_75 flex-nowrap justify-content-between" style={usurpSsaStl.borderBottom}>
          <Col className="pl-0 pr-0_5">
            {isAds10212Enabled ? (
              <Fragment>
                <ContentFragment componentToUse="h2" classes="mb-0_5 size-16 font-weight-bold text-cool-gray-10">
                  {get(creativeConfig, 'creativeConfigData.subheadline', '')}
                </ContentFragment>
                {['chal3', 'chal2'].includes(ads10212Recipe) && (
                  <div className="medium" style={usurpSsaStl.headline}>
                    Build Your {modelNmae}
                  </div>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <ContentFragment componentToUse="h2" classes="mb-0_5 size-16 font-weight-bold text-blue-50">
                  {headlineText}
                </ContentFragment>
                <ContentFragment classes="medium" inlineStyle={usurpSsaStl.headline}>
                  {get(creativeConfig, 'creativeConfigData.subheadline', '')}
                </ContentFragment>
              </Fragment>
            )}
          </Col>
          {showMsrpPrice && msrpPriceDisplay}
        </Row>
        {isAds10212Enabled ? (
          <Fragment>
            {isAds10212Chal1 && <div className="font-italic font-weight-medium pb-0_5">Build Your {modelNmae}</div>}
            <ul
              style={usurpSsaStl.ul}
              className={classnames('at-a-glance-container size-16 font-weight-normal mt-0_5', {
                'is-ads-10212-chal1-list': isAds10212Chal1,
              })}
              aria-label="At a glance"
            >
              {['chal1', 'chal2'].includes(ads10212Recipe)
                ? ADS_10212_SUBHEADS.map((subhead, index) => (
                    <li
                      className="pr-0_5 text-nowrap d-flex align-items-center"
                      key={`${subhead.title}-${ads10212Recipe}`}
                      style={{
                        marginBottom: isAds10212Chal1 ? '14px' : '1rem',
                      }}
                    >
                      {ads10212Recipe === 'chal1' && (
                        <span
                          className="text-white bg-blue-50 rounded-circle text-center"
                          style={{
                            marginRight: '1rem',
                            height: '20px',
                            width: '20px',
                            lineHeight: '20px',
                          }}
                          aria-hidden
                        >
                          {index + 1}
                        </span>
                      )}
                      {isADS10212Chal2 && (
                        <span className={`${subhead.icon} mr-0_5 text-blue-50`} aria-hidden style={{ width: '20px' }} />
                      )}
                      <span>{subhead.title}</span>
                    </li>
                  ))
                : renderAtAGlanceList}
            </ul>
          </Fragment>
        ) : (
          <ul
            style={usurpSsaStl.ul}
            className="at-a-glance-container size-14 font-weight-normal mt-0_5"
            aria-label="At a glance"
          >
            {renderAtAGlanceList}
          </ul>
        )}
        <div style={{ marginTop: 'auto' }}>
          <div
            className={classnames(
              'cta btn rounded-8 w-100 btn-outline-blue-50 btn-sm text-capitalize size-16 font-weight-medium',
              { 'btn-blue-50': !isConquest, 'btn-outline-blue-50': isConquest }
            )}
          >
            <CtaButtonText text={cta || CTA} />
          </div>
          <div className="small text-blue-50 text-center mt-1" style={{ lineHeight: '.875rem' }} role="presentation">
            {linkDisplayUrl}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

UsurpSiteServedContent.propTypes = {
  creativeConfig: SRPCreativeConfigPropTypes.isRequired,
  targetVehicle: VehicleEntities.Vehicle.isRequired,
  mobile: PropTypes.bool,
  ads10212Recipe: PropTypes.string,
};

UsurpSiteServedContent.defaultProps = {
  mobile: false,
  ads10212Recipe: null,
};
