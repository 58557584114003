import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { addZipCode, definePlatform } from 'client/site-modules/shared/components/native-ad/utils/utils';
import { DfpTracking } from 'client/site-modules/shared/components/native-ad/utils/dfp-tracking';
import {
  SRPCreativeConfigDefaultProps,
  SRPCreativeConfigPropTypes,
} from 'client/data/models/native-ads-creative-config';
import {
  AdUnitDefaultProps,
  AdUnitPropTypes,
  SiteServedAdDefaultProps,
  SiteServedAdPropTypes,
} from 'client/site-modules/shared/components/ad-unit/ad-unit-prop-types';
import { SRP_AD } from 'site-modules/shared/components/native-ad/utils/constants';

import { siteServedAdTrackingWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-tracking-wrapper/site-served-ad-tracking-wrapper';
import { getTrackingValue } from 'site-modules/shared/components/native-ad/utils/offer-incentives-ad-utils';

import { AdLabel } from 'client/site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import { Disclaimer } from 'site-modules/shared/components/native-ad/native-ad-components/disclaimer/disclaimer';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';

import { usurpSsaStl } from './usurp-site-served-ad-styles';
import { UsurpSiteServedContent } from './usurp-site-served-content';

import './usurp-site-served-ad.scss';

const HEADLINE_DEFAULT = 'Special offers available';

export function UsurpSiteServedAdUI({
  adRef,
  adLoadTrackingListener,
  adClickTrackingListener,
  adTrackers,
  creativeConfig,
  mobile,
  currentVehicle,
  vehicle,
  dependedOnCurrentVehicle,
  customTargeting,
  position,
}) {
  const {
    siteServedAdsUpdater,
    zipCode,
    ipZipCode,
    offersNumber,
    creativeConfigData: { headline, disclaimerCopy, lineItemId, siteServedCreativeId, target },
    traffickingData: { clickTracker },
  } = creativeConfig;
  const loadTrackingListener = useCallback(
    event => {
      const trackingValue = getTrackingValue(headline, offersNumber, zipCode, ipZipCode, HEADLINE_DEFAULT, 14, 10);
      adLoadTrackingListener(event, trackingValue);
    },
    [adLoadTrackingListener, headline, ipZipCode, offersNumber, zipCode]
  );

  const ctaURL = addZipCode(clickTracker[definePlatform(mobile)], zipCode);
  const ctaTarget = target || '_blank';
  const targetVehicle = currentVehicle || vehicle;

  return (
    <div className="usurp-ssa h-100 w-100 pos-r" ref={adRef} style={usurpSsaStl.usurpSsa}>
      <DfpTracking
        lineItemId={lineItemId}
        siteServedCreativeId={siteServedCreativeId}
        mobile={mobile}
        nativeStyle={SRP_AD.NATIVE_STYLE}
        currentVehicle={currentVehicle}
        dependedOnCurrentVehicle={dependedOnCurrentVehicle}
        customTargeting={customTargeting}
        position={position}
        slotRenderEndListener={loadTrackingListener}
        siteServedAdsUpdater={siteServedAdsUpdater}
        rerenderWhenViewable
      />
      {adTrackers}
      <a
        href={ctaURL}
        target={ctaTarget}
        className="site-served-ad-link no-focus pos-r h-100 d-flex flex-column text-decoration-none"
        rel="noopener noreferrer"
        onClick={adClickTrackingListener}
      >
        <AdLabel />
        {position === '2' ? (
          <Experiment name="ads-10212" showDefault>
            <Recipe name="ctrl" isDefault>
              <UsurpSiteServedContent creativeConfig={creativeConfig} targetVehicle={targetVehicle} mobile={mobile} />
            </Recipe>
            <Recipe name="chal1">
              <UsurpSiteServedContent
                creativeConfig={creativeConfig}
                targetVehicle={targetVehicle}
                mobile={mobile}
                ads10212Recipe="chal1"
              />
            </Recipe>
            <Recipe name="chal2">
              <UsurpSiteServedContent
                creativeConfig={creativeConfig}
                targetVehicle={targetVehicle}
                mobile={mobile}
                ads10212Recipe="chal2"
              />
            </Recipe>
            <Recipe name="chal3">
              <UsurpSiteServedContent
                creativeConfig={creativeConfig}
                targetVehicle={targetVehicle}
                mobile={mobile}
                ads10212Recipe="chal3"
              />
            </Recipe>
          </Experiment>
        ) : (
          <UsurpSiteServedContent creativeConfig={creativeConfig} targetVehicle={targetVehicle} mobile={mobile} />
        )}
      </a>
      {disclaimerCopy && (
        <Disclaimer
          linkDisclaimer="Disclaimer"
          disclaimerText={disclaimerCopy}
          buttonStyle={{ position: 'absolute', bottom: '6px', right: '6px' }}
        />
      )}
    </div>
  );
}

UsurpSiteServedAdUI.propTypes = {
  ...SiteServedAdPropTypes,
  creativeConfig: SRPCreativeConfigPropTypes,
  customTargeting: AdUnitPropTypes.customTargeting,
  dependedOnCurrentVehicle: PropTypes.bool,
  wrapperClass: PropTypes.string,
};

UsurpSiteServedAdUI.defaultProps = {
  ...SiteServedAdDefaultProps,
  creativeConfig: SRPCreativeConfigDefaultProps,
  dependedOnCurrentVehicle: false,
  customTargeting: AdUnitDefaultProps.customTargeting,
  wrapperClass: '',
};

export const UsurpSiteServedAd = siteServedAdTrackingWrapper(UsurpSiteServedAdUI);
